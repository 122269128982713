import { HttpClient, HttpHeaders, HttpParams } from '@angular/common/http';
import { Inject, Injectable } from '@angular/core';
// import { APP_CONFIG } from '@guardstreet/app-config';

// import { Environment } from '../interfaces/environment';


@Injectable({
  providedIn: 'root',
})
export class GetUserService {
  constructor(
    private http: HttpClient,
   // @Inject(APP_CONFIG) private appConfig: Environment
  ) {}

  getUser() {
    return this.http.get('/api/users/me');
  }

  getUserInfo(uuid: string) {
    const endpoint = `api/user-infos/${uuid}`;
    return this.http.get(endpoint);
  }

  // setUserHostPreference(mode) {
  //   return this.http.put(
  //     this.appConfig.securePfiApiUrl + 'api/user-session/access-mode/' + mode,
  //     null
  //   );
  // }

  // getNotifications(isHost, size, page, unread?: boolean) {
  //   let Params = new HttpParams();
  //   Params = Params.append('size', size);
  //   Params = Params.append('page', page);
  //   Params = Params.append('sort', 'createdDate,desc');

  //   if (unread !== undefined) {
  //     Params = Params.append('unread', unread ? 'true' : 'false');
  //   }

  //   let mode = 'host';
  //   if (!isHost) mode = 'guest';

  //   return this.http.get(
  //     this.appConfig.securePfiApiUrl + 'api/' + mode + '/notifications',
  //     {
  //       headers: new HttpHeaders().set('HOR-SHOWLOADING', 'false'),
  //       params: Params,
  //     }
  //   );
  // }

  /*
  getNotification(isHost) {
    if (isHost) {
      return this.http.get(this.appConfig.legacyApiUrl + "api/host/notifications");
    } else {
      return this.http.get(this.appConfig.legacyApiUrl + "api/guest/notifications");
    }
  }
  */

  // notificationRead(id) {
  //   return this.http.put(
  //     this.appConfig.securePfiApiUrl + 'api/notifications/' + id + '/read',
  //     {}
  //   );
  // }
  // deleteNotification(id) {
  //   return this.http.delete(
  //     this.appConfig.securePfiApiUrl + 'api/notifications/' + id,
  //     {}
  //   );
  // }
}

import { Routes } from '@angular/router';
import { AuthGuard } from '@guardstreet/data-access-shared/auth/auth.guard';
import { BenefitsPageComponent } from '@guardstreet/ui-shared';

export const appRoutes: Routes = [
  {
    path: 'login',
    loadChildren: () =>
      import('@guardstreet/login').then((module) => module.LoginModule),
  },
  {
    path: 'admin',
    loadChildren: () =>
      import('@guardstreet/dashboard').then((module) => module.DashboardModule),
    canActivate: [AuthGuard],
  },
  {
    path: 'benefits',
    component: BenefitsPageComponent,
    canActivate: [AuthGuard]
  },  
  { path: 'forgotpass', redirectTo: 'login/forgotpass' },
  { path: 'register', redirectTo: 'login/register' },
  { path: 'forgotpass', redirectTo: 'login/forgotpass' },
  { path: '**', redirectTo: 'login' },
];

export class AppRoutingModule {}

import { Injectable } from '@angular/core';
import {
  CanActivate,
  ActivatedRouteSnapshot,
  RouterStateSnapshot,
  Router,
  ActivatedRoute,
} from '@angular/router';
import { Observable } from 'rxjs/Observable';
import { AuthService } from '../auth/auth.service';
import { map } from 'rxjs/operators';

@Injectable({
  providedIn: 'root',
})
export class AuthGuard implements CanActivate {
  constructor(
    private authService: AuthService,
    private router: Router,
    private route: ActivatedRoute
  ) {}

  canActivate(
    route: ActivatedRouteSnapshot,
    state: RouterStateSnapshot
  ): Observable<boolean> | Promise<boolean> | boolean {
    this.authService.stateChange(state.url);
    console.log('Guarding...' + state.url);

    const JWT = sessionStorage.getItem('hor-token');

    if (JWT !== null && JWT !== '') {
      return true;
    } else {
      this.router.navigate(['login'], {
        relativeTo: this.route.parent,
      });
      return false;
    }
  }
}

import { ApplicationConfig, importProvidersFrom } from '@angular/core';
import { provideRouter } from '@angular/router';
import { appRoutes } from './app.routes';
import { provideClientHydration } from '@angular/platform-browser';
import { provideAnimations } from '@angular/platform-browser/animations';
import { CommonModule } from '@angular/common';
import { provideHttpClient, withInterceptors } from '@angular/common/http';
import { NgxsModule } from '@ngxs/store';
import { ClientsState, MembersState, UsersState } from '@guardstreet/data-access-shared';
import { NgxsReduxDevtoolsPluginModule } from '@ngxs/devtools-plugin';
import { HttpClientModule } from '@angular/common/http';
import { NgxsFormPluginModule } from '@ngxs/form-plugin';
import { interceptInterceptor } from './intercept.interceptor';
import { BenefitsState } from '@guardstreet/data-access-shared/states/benefit.state';
import { NgxsStoragePluginModule, StorageOption } from '@ngxs/storage-plugin';
import { APP_CONFIG } from '@guardstreet/app-config';
import { environment } from '../environments/environment';

export const appConfig: ApplicationConfig = {
  providers: [provideRouter(appRoutes),
    provideHttpClient(withInterceptors([interceptInterceptor])),
    importProvidersFrom(NgxsModule.forRoot([ClientsState, MembersState, UsersState, BenefitsState],)),
    importProvidersFrom(HttpClientModule),
    importProvidersFrom(NgxsReduxDevtoolsPluginModule.forRoot()),
    importProvidersFrom(NgxsFormPluginModule.forRoot()),
    importProvidersFrom(NgxsStoragePluginModule.forRoot({storage: StorageOption.SessionStorage,
      key: [MembersState, ClientsState, UsersState, BenefitsState]
    })),
    provideClientHydration(), CommonModule, provideAnimations(), { provide: APP_CONFIG, useValue: environment }
  ], };

import { HttpErrorResponse, HttpEvent, HttpInterceptorFn, HttpResponse } from '@angular/common/http';
import { AuthActions } from '@guardstreet/data-access-shared/states/auth/auth.action';
import { GlobalEventsActions } from '@guardstreet/data-access-shared/states/global-events/global-events.action';
import { HttpStatusActions } from '@guardstreet/data-access-shared/states/http-status/http-status.action';
import { tap } from 'rxjs/operators';
import { Store } from '@ngxs/store';

export const interceptInterceptor: HttpInterceptorFn = (req, next) => {
    
    //base url, new endpoint us suffixed with /sabre

    // add secure endpoint for PFI
    const apiUrlBasePfi = 'api/v1/admin';//this.appConfig.securePfiApiUrl;

    let addJWT =
      req.url.includes(apiUrlBasePfi)

    if (req.headers.has('HOR-JWT')) {
      if (req.headers.get('HOR-JWT') === 'false') addJWT = false;
      //console.log("removing")
      const headers = req.headers.delete('HOR-JWT');
      req = req.clone({ headers: headers });
    }

  //  this.store.dispatch(new HttpStatusActions.SetLoading(true));

    //get token from localStorage or sessionStorage depending on remember me setting, they should never be both present
    let JWT = sessionStorage.getItem('hor-token');
    if (
      localStorage.getItem('hor-token') !== '' &&
      localStorage.getItem('hor-token') !== null
    )
      JWT = localStorage.getItem('hor-token');
      
    if (addJWT && JWT !== null && JWT !== '') {
      req = req.clone({
        setHeaders: {
          Authorization: JWT,
        },
      });
    //this.store.dispatch(new AuthActions.Authenticated(true));
    }

    // if (
    //   req.url.startsWith(apiUrlBasePfi)      
    // ) {
    //   req = req.clone({
    //     setHeaders: {
    //       W3AI_API: '1234',
    //     },
    //   });
    // }

    return next(req).pipe(tap(
      (event: HttpEvent<any>) => {
        if (event instanceof HttpResponse) {
        //  this.store.dispatch(new HttpStatusActions.SetLoading(false));
        }
      },
      (err: HttpErrorResponse) => {
        if (err instanceof HttpErrorResponse) {
        //  this.store.dispatch(new HttpStatusActions.SetLoading(false));
        //  this.store.dispatch(new GlobalEventsActions.HideBusyLoading());

          if (err.status === 401) {
            localStorage.clear();
            sessionStorage.clear();
          //   this.authService.setUserData(null);
          //  this.store.dispatch(new AuthActions.Authenticated(false));
          }
          const message = err.error ? err.error.detail : err.message;
     //     this.store.dispatch(new HttpStatusActions.SetError(message));
        }
      })
    );

  return next(req);
};

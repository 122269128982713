import { Component } from '@angular/core';
import { RouterModule } from '@angular/router';
import { UiSharedModule } from '@guardstreet/ui-shared';
import { environment } from '../environments/environment';


@Component({
  standalone: true,
  imports:[RouterModule, UiSharedModule],
  selector: 'guardstreet-root',
  templateUrl: './app.component.html',
  styleUrl: './app.component.scss',
})
export class AppComponent {
  constructor() {
    if(environment.production) { // 👈🏻👈🏻👈🏻
      console.log("PROD")
    }
    else{
      console.log("DEV")
    }
  }
  title = 'guardstreet';
}
